import React from 'react';
import robot from '../assets/robot.png'
import simpsons from '../assets/simpsons.jpeg'
import realscreen from '../assets/realscreen.jpg'

const FrontPage = () => {
  return (
    <div>

      <div class="hero">
        <div class="container mx-auto px-24 py-12 flex flex-col sm:flex-row justify-between items-center">
            <div class="text-left">
                <p class="max-w-2xl mb-4 font-light text-gray-300 md:text-lg lg:text-xl">Hello, My name's Ray</p>
                <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-textGreen">Full Stack Developer</h1>
                {/* <p class="max-w-2xl mb-4 font-light text-gray-300 md:text-lg lg:text-xl">On a mission to be able to build, break or fix anything!</p> */}
                {/* <a href="#" class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                    Get started
                    <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a>
                <a href="#" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                    Speak to Sales
                </a>  */}
            </div>
            <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                <img src={robot} alt="mockup"/>
            </div>                
        </div>
      </div>

      {/* <div className='h-64 my-64'>About</div> */}

      <div id='skills' className='my-16 mb-32'>
        <h2 className='mb-4 text-4xl font-bold tracking-tight leading-none text-textGreen'>Skills</h2>
        <div className='container mx-auto flex flex-wrap justify-center px-24'>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>JavaScript</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>React</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Python</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Django</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>PostGres</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Tailwind</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Git</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Github</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Postman</h4>
          </div>
        </div>
      </div>            
    

    {/* <div>
      <div className='container mx-auto'>
        <h2 className='mb-4 text-4xl font-bold tracking-tight leading-none text-textGreen'>My Work</h2>
        <div className='container mx-auto flex flex-wrap justify-center px-24'>
          <div className='border border-borderGray largeradius1 m-4 w-1/4 flex flex-col overflow-hidden transform transition-transform duration-200 hover:-translate-y-2 hover:shadow-lg hover:shadow-green-500/50'>
            <img src={realscreen} className='w-full h-32 object-cover'/>
            <div className='px-4 pb-4 pt-3 text-left text-left  bg-borderGray'>
              <h4 className='font-bold text-gray-300'>Tradebooks.io</h4>
              <p className='font-light text-gray-300'>reokjipsfbvi ivb ibrv udbgfr8 wurgjsdfb ygr8ugvicb</p>
              <div className='skills flex flex-wrap justify-start mt-2'>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 mr-2 my-1 rounded-lg'>Django</div>
              </div>
            </div>
          </div>
          <div className='border border-borderGray largeradius1 m-4 w-1/4 flex flex-col overflow-hidden transform transition-transform duration-200 hover:-translate-y-2 hover:shadow-lg hover:shadow-green-500/50'>
            <img src={simpsons} className='w-full h-32 object-cover'/>
            <div className='px-4 pb-4 pt-3 text-left  bg-borderGray'>
              <h4 className='font-bold text-gray-300'>Tradebooks.io</h4>
              <p className='font-light text-gray-300'>reokjipsfbvi ivb ibrv udbgfr8 wurgjsdfb ygr8ugvicb</p>
              <div className='skills flex flex-wrap justify-start mt-2'>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 mr-2 my-1 rounded-lg'>Django</div>
              </div>
            </div>
          </div>
          <div className='border border-borderGray largeradius1 m-4 w-1/4 flex flex-col overflow-hidden transform transition-transform duration-200 hover:-translate-y-2 hover:shadow-lg hover:shadow-green-500/50'>
            <img src={simpsons} className='w-full h-32 object-cover'/>
            <div className='px-4 pb-4 pt-3 text-left bg-borderGray'>
              <h4 className='font-bold text-gray-300'>Tradebooks.io</h4>
              <p className='font-light text-gray-300'>reokjipsfbvi ivb ibrv udbgfr8 wurgjsdfb ygr8ugvicb</p>
              <div className='skills flex flex-wrap justify-start mt-2'>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 mr-2 my-1 rounded-lg'>Django</div>
              </div>
            </div>
          </div>
          <div className='border border-borderGray largeradius1 m-4 w-1/4 flex flex-col overflow-hidden transform transition-transform duration-200 hover:-translate-y-2 hover:shadow-lg hover:shadow-green-500/50'>
            <img src={simpsons} className='w-full h-32 object-cover'/>
            <div className='px-4 pb-4 pt-3 text-left bg-borderGray'>
              <h4 className='font-bold text-gray-300'>Tradebooks.io</h4>
              <p className='font-light text-gray-300'>reokjipsfbvi ivb ibrv udbgfr8 wurgjsdfb ygr8ugvicb</p>
              <div className='skills flex flex-wrap justify-start mt-2'>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 mr-2 my-1 rounded-lg'>Django</div>
              </div>
            </div>
          </div>
          <div className='border border-borderGray largeradius1 m-4 w-1/4 flex flex-col overflow-hidden transform transition-transform duration-200 hover:-translate-y-2 hover:shadow-lg hover:shadow-green-500/50'>
            <img src={simpsons} className='w-full h-32 object-cover'/>
            <div className='px-4 pb-4 pt-3 text-left bg-borderGray'>
              <h4 className='font-bold text-gray-300'>Tradebooks.io</h4>
              <p className='font-light text-gray-300'>reokjipsfbvi ivb ibrv udbgfr8 wurgjsdfb ygr8ugvicb</p>
              <div className='skills flex flex-wrap justify-start mt-2'>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 mr-2 my-1 rounded-lg'>Django</div>
              </div>
            </div>
          </div>
          <div className='border border-borderGray largeradius1 m-4 w-1/4 flex flex-col overflow-hidden transform transition-transform duration-200 hover:-translate-y-2 hover:shadow-lg hover:shadow-green-500/50'>
            <img src={simpsons} className='w-full h-32 object-cover'/>
            <div className='px-4 pb-4 pt-3 text-left bg-borderGray'>
              <h4 className='font-bold text-gray-300'>Tradebooks.io</h4>
              <p className='font-light text-gray-300'>reokjipsfbvi ivb ibrv udbgfr8 wurgjsdfb ygr8ugvicb</p>
              <div className='skills flex flex-wrap justify-start mt-2'>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 pb-1 mr-2 my-1 rounded-lg'>Django</div>
                <div className='text-white bg-borderGray px-2 mr-2 my-1 rounded-lg'>Django</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}


    <div id='contact' className='my-16 mb-32'>
        <h2 className='mb-4 text-4xl font-bold tracking-tight leading-none text-textGreen'>Get In Touch</h2>
        <div className='container mx-auto flex flex-wrap justify-center px-24'>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Email</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Telegram</h4>
          </div>
          <div className='border border-borderGray bg-borderGray largeradius1 p-4 m-4 hover:bg-textGreen'>
            <img src={robot} className='h-36 w-36 '/>
            <h4>Discord</h4>
          </div>
          
        </div>
      </div>

    </div>
  );
};

export default FrontPage;