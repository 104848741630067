import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import FrontPage from './components/FrontPage';

function App() {
  return (
    <div className="App bg-backGray min-h-screen py-6">
      <Router>
        <Routes>

          <Route element={<Layout />}>
            <Route path='/' element={<FrontPage />} />  
          </Route>
          
        </Routes>
      </Router>

    </div>
  );
}

export default App;
