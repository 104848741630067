// src/components/Header.js
import React from 'react';
import { NavLink} from 'react-router-dom';

const Header = () => {
  return (
    <header className="container mx-auto border border-borderGray px-5 py-3 largeradius1">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-xl font-bold text-gray-400">
          <h1 className='text-gray-100'><span className='text-textGreen'>&lt;</span>rayHat <span className='text-textGreen'>/&gt;</span></h1>
        </div>
        <nav>
          <ul className="flex space-x-4">

            <li>
              <NavLink to="/" className="text-gray-300 text-md hover:text-white">Home</NavLink>
            </li>
            {/* <li>
              <NavLink to="/" className="text-gray-300 text-md hover:text-white">About Me</NavLink>
            </li> */}
            <li>
              <a href="#skills" className="text-gray-300 text-md hover:text-white">Skills</a>
            </li>
            {/* <li>
              <NavLink to="/" className="text-gray-300 text-md hover:text-white">Work</NavLink>
            </li> */}
          </ul>
        </nav>
        <a href='#contact' className='bg-textGreen text-white px-4 py-2 largeradius2'>Contact</a>
      </div>
    </header>
  );
};

export default Header;
